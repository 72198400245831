/* eslint-disable @nx/enforce-module-boundaries */
import { PinIcon } from '@mybridge/icons';
import { Button, Link, SeeMore, Tooltip } from '@mybridge/ui';
import { Avatar } from '@mybridge/ui/avatar';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MyNetworkContext } from 'v4/components/network/context';
import { PostFeelingAndTagsText } from 'v4/components/post-form/feeling-tag-text';
import { UserDetailsPopover } from 'v4/components/user-details-popover';
import {
  getUserFullName,
  getUserPosition,
  getUserProfilePic,
} from 'v4/lib/commons';
import { PostBoxContext } from '../context';
import { PostActionsMenu } from '../post-actions-menu';
import PostTiming from '../post-timing';
import moment from 'moment';
import { ForceSignInWrapper } from 'v4/components/force-sign-in-wrapper';
import { useRouter } from 'next/router';
import { CompanyDetailsPopover } from 'v4/components/company/company-details-popover';
import { PostActivityHeader } from '../activity-header';
import { CircleIcon, NewGlobe, Globe, NewReply } from '@mybridge/icons';
import { CircleAndFollowers } from '@mybridge/icons/CircleAndFollowers';
import { isMobile } from 'react-device-detect';
import Image from 'next/image';
export const PostBoxHeader = ({
  actionMenuEnabled = true,
  isOnlyPreview,
  quoted,
  isFromSearch = false,
}) => {
  const {
    post,
    acceptEvent,
    acceptEventLoading,
    ignoreEvent,
    ignoreEventLoading,
    userProfileInfo,
    loggedIn,
    setupAdd,
  } = useContext(PostBoxContext);
  const { push } = useRouter();
  const {
    id,
    author,
    start_time,
    end_time,
    type,
    company,
    pinned,
    feelings,
    tagged_users: tagged,
    title,
    reply_control,
    visibility,
    description,
    attendees,
    excerpt,
    event_type,
    page,
  } = post || {};
  const position = company?.name?.length
    ? company?.name
    : getUserPosition(author);
  // const location = company?.country !== '' ? company?.country : author?.location;
  const { feelingsAndActivitiesArr } = useSelector((s) => s.globalData) ?? {};
  const feeling = feelingsAndActivitiesArr?.find?.((v) => v.id == feelings?.id);
  const userFullName = useMemo(() => getUserFullName(author), [author]);
  const userProfilePic = useMemo(() => getUserProfilePic(author), [author]);

  const isProfilePicPost =
    post?.content === 'USER_PROFILE_PIC_UPDATE' ||
    post?.content === 'COMPANY_PROFILE_PIC_UPDATE';
  const isProfileBannerPost =
    post?.content === 'USER_PROFILE_BANNER_PIC_UPDATE' ||
    post?.content === 'COMPANY_PROFILE_BANNER_PIC_UPDATE';

  const link = company?.name?.length
    ? `/c/${company?.slug}`
    : `/p/${author?.slug}`;
  const isEvent = type === 2;
  const isArticle = type === 3;
  const isAdPost = type === 4;
  const startTime = start_time
    ? moment(start_time).format('MMM DD, hh:mm a')
    : null;
  const endTime = start_time
    ? moment(end_time).format('MMM DD, hh:mm a')
    : null;

  const attending = attendees?.find?.(
    (a) => a?.user?.id === userProfileInfo?.id
  );

  const replyControlButton = (key) => {
    const config = {
      0: {
        icon: (
          <Tooltip label="Everyone" bg="#868585" py={1}>
            <Box as="span">
              <NewGlobe width={15} height={15} color="#868585" />
            </Box>
          </Tooltip>
        ),
        title: 'Everyone',
        value: 0,
      },
      1: {
        icon: (
          <Tooltip label="myCircle" bg="#868585" py={1}>
            <Box as="span">
              <CircleIcon width={15} height={15} color="#868585" />
            </Box>
          </Tooltip>
        ),
        title: 'myCircle',
        value: 1,
      },
      2: {
        icon: (
          <Tooltip label="myCircle & followers" bg="#868585" py={1}>
            <Box as="span">
              <CircleAndFollowers width={15} height={15} color="#868585" />
            </Box>
          </Tooltip>
        ),
        title: 'myCircle & followers',
        value: 2,
      },
    };
    return config?.[key]?.icon;
  };

  return (
    <>
      {loggedIn &&
      !isOnlyPreview &&
      !quoted &&
      !isFromSearch &&
      (!isEvent || !isArticle) ? (
        <PostActivityHeader pinned={pinned} />
      ) : (
        ''
      )}

      <Link href={'/a/' + id} display="none" />
      {!isOnlyPreview && !(isFromSearch && isEvent || isArticle)&&(
        <HStack
          p={2}
          spacing={3}
          alignItems="flex-start"
          zIndex="0"
          style={{ marginTop: '12px', padding: '0 14px 8px 14px' }}
        >
          <Stack>
            {isMobile ? (
              company?.image || userProfilePic ? (
                <Image
                  src={company?.image?.length ? company?.image : userProfilePic}
                  height="40"
                  width="40"
                  style={{ borderRadius: '50%' }}
                  loading="lazy"
                  alt={company?.name?.length ? company?.name : userFullName}
                />
              ) : (
                <Avatar
                  as={Link}
                  href={link}
                  size="md"
                  name={company?.name?.length ? company?.name : userFullName}
                  src={company?.image?.length ? company?.image : userProfilePic}
                  loading="lazy"
                />
              )
            ) : (
              <UserDetailsPopover
                user={author}
                company={company?.name?.length ? company : null}
              >
                {company?.image || userProfilePic ? (
                  <Image
                    src={
                      company?.image?.length ? company?.image : userProfilePic
                    }
                    height="40"
                    width="40"
                    style={{ borderRadius: '50%', height: '40px' }}
                    loading="lazy"
                    alt={company?.name?.length ? company?.name : userFullName}
                  />
                ) : (
                  <Avatar
                    as={Link}
                    href={link}
                    size="md"
                    name={company?.name?.length ? company?.name : userFullName}
                    src={
                      company?.image?.length ? company?.image : userProfilePic
                    }
                    loading="lazy"
                  />
                )}
              </UserDetailsPopover>
            )}
          </Stack>
          <HStack flex={1} alignItems="flex-start">
            <Stack pt={0} spacing={0} flex={1}>
              <Box lineHeight="1.2">
                {isEvent ? (
                  <HStack as={Link} href={`/myevents/details/${id}`}>
                    <Box flex={1}>
                      <Text fontSize="sm" display="inline">
                        Event -{' '}
                      </Text>
                      <Text fontSize="sm" display="inline">
                        {startTime}
                      </Text>
                    </Box>
                  </HStack>
                ) : (
                  ''
                )}

                {company?.name?.length ? (
                  <>
                    {isMobile ? (
                      <Heading
                        display="inline"
                        color="brandPrimary.500"
                        fontWeight="medium"
                        size="sm"
                        as={Link}
                        href={`/c/${company?.slug}`}
                      >
                        {company?.name}{' '}
                        {isProfilePicPost && (
                          <Text as="span" fontWeight="400">
                            updated their profile picture
                          </Text>
                        )}
                        {isProfileBannerPost && (
                          <Text as="span" fontWeight="400">
                            updated their cover picture
                          </Text>
                        )}
                      </Heading>
                    ) : (
                      <UserDetailsPopover company={company}>
                        <Heading
                          display="inline"
                          color="brandPrimary.500"
                          fontWeight="medium"
                          size="sm"
                          as={Link}
                          href={`/c/${company?.slug}`}
                        >
                          {company?.name}{' '}
                          {isProfilePicPost && (
                            <Text as="span" fontWeight="400">
                              updated their profile picture
                            </Text>
                          )}
                          {isProfileBannerPost && (
                            <Text as="span" fontWeight="400">
                              updated their cover picture
                            </Text>
                          )}
                        </Heading>
                      </UserDetailsPopover>
                    )}
                  </>
                ) : (
                  <>
                    {isMobile ? (
                      <Heading
                        display="inline"
                        color="brandPrimary.500"
                        fontWeight="medium"
                        size="sm"
                        as={Link}
                        href={link}
                      >
                        {userFullName}
                      </Heading>
                    ) : (
                      <UserDetailsPopover user={author}>
                        <Heading
                          display="inline"
                          color={
                            isProfilePicPost || isProfileBannerPost
                              ? '#5B5B5B'
                              : 'brandPrimary.500'
                          }
                          fontWeight="medium"
                          size="sm"
                          as={Link}
                          href={link}
                        >
                          {userFullName}{' '}
                          {isProfilePicPost && (
                            <Text as="span" fontWeight="400">
                              updated his profile picture
                            </Text>
                          )}
                          {isProfileBannerPost && (
                            <Text as="span" fontWeight="400">
                              updated his cover picture
                            </Text>
                          )}
                        </Heading>
                      </UserDetailsPopover>
                    )}
                  </>
                )}
                <PostFeelingAndTagsText tagged={tagged} feeling={feeling} />
                {/* {pinned ? (
                <HStack>
                  <PinIcon color="rgba(0,0,0,0.3)" width="16" height="16" />
                  <Text color="gray.500">Pinned</Text>
                </HStack>
              ) : (
                ''
              )} */}
              </Box>
              {isProfilePicPost || isProfileBannerPost ? (
                <></>
              ) : (
                // <Text fontSize="sm" color="brandGray.700">
                //   {position}
                // </Text>
                <></>
              )}
              <Stack flex={1} direction="row" alignItems="center">
                {type === 4 && (
                  <Text fontSize="xs" color="brandGray.700">
                    Sponsored
                  </Text>
                )}
              </Stack>
              <Stack flex={1} direction="row" alignItems="center">
                {replyControlButton(visibility)}{' '}
                <Text
                  style={{
                    height: 5,
                    width: 5,
                    backgroundColor: '#747474',
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                ></Text>
                <PostTiming />
              </Stack>
              <Box display={['block', 'block', 'none']} mt={3}>
                {isEvent ? (
                  attending ? (
                    attending?.status === 'IGNORE' ? (
                      <Box p={2}>
                        <Text size="xs">Ignored</Text>
                      </Box>
                    ) : attending?.status === 'ACCEPT' ? (
                      <Box p={2}>
                        <Text size="xs">RSVP'ed</Text>
                      </Box>
                    ) : (
                      ''
                    )
                  ) : (
                    <ForceSignInWrapper>
                      <HStack>
                        {/* <Button
                          isLoading={ignoreEventLoading}
                          onClick={(e) => {
                            ignoreEvent(post);
                          }}
                        >
                          Ignore
                        </Button> */}
                        {/* <Button
                        isLoading={acceptEventLoading}
                        onClick={(e) => {
                          acceptEvent(post);
                        }}
                        variant="secondary"
                      >
                        {event_type === 'general' ? 'RSVP' : 'Register'}
                      </Button> */}
                      </HStack>
                    </ForceSignInWrapper>
                  )
                ) : (
                  ''
                )}
              </Box>
            </Stack>
            {actionMenuEnabled ? (
              <HStack position="relative" zIndex="3">
                <Box display={['none', 'none', 'block']}>
                  {isEvent ? (
                    attending ? (
                      attending?.status === 'IGNORE' ? (
                        <Box p={2}>
                          <Text size="xs">Ignored</Text>
                        </Box>
                      ) : attending?.status === 'ACCEPT' ? (
                        <Box p={2}>
                          <Text size="xs">RSVP'ed</Text>
                        </Box>
                      ) : (
                        ''
                      )
                    ) : (
                      <ForceSignInWrapper>
                        <HStack>
                          {/* <Button
                          isLoading={ignoreEventLoading}
                          onClick={(e) => {
                            ignoreEvent(post);
                          }}
                          variant="ghost"
                        >
                          Ignore
                        </Button> */}
                          {/* <Button
                          isLoading={acceptEventLoading}
                          onClick={(e) => {
                            acceptEvent(post);
                          }}
                          variant="secondary"
                          color="#5B5B5B"
                          size="sm"
                          fontSize="12px"
                        >
                          {event_type === 'general' ? 'RSVP' : 'Register'}
                        </Button> */}
                        </HStack>
                      </ForceSignInWrapper>
                    )
                  ) : (
                    ''
                  )}
                </Box>
                {!isEvent && !isAdPost && !isOnlyPreview ? (
                  <ForceSignInWrapper>
                    <PostActionsMenu />
                  </ForceSignInWrapper>
                ) : (
                  ''
                )}
              </HStack>
            ) : (
              ''
            )}
          </HStack>
        </HStack>
      )}
    </>
  );
};
